import { css } from "@emotion/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { Grid, IconButton, Menu, MenuItem } from "@mui/material";

import { useApiConnector } from "@/custom-hooks/apis-common/use-api-connector";
import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";
import { ENV } from "@/utils/env";
import { mqMinScreen } from "@/utils/mediaQuery";

import SettingMenu from "../Menu/SettingMenu";

import {
  achievementsPath,
  eventListPath,
  eventRoot,
  planListPath,
  planRoot,
  reservationListPath,
  reservationRoot,
  routePath,
} from "@/constants/app-paths";
import { LocalForageKeys } from "@/constants/local-forage-keys";
import { selectedSpotIdAtom } from "@/global-states/common-atoms";
import { SvgIconCaretDown, SvgIconCompany, SvgIconDownArrow, SvgIconSetting } from "@/svgs";
import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import localforage from "localforage";

type Props = {
  screenShowNot?: boolean;
};

const Header: React.FC<Props> = (props: Props) => {
  const APP_ENV = process.env.APP_ENV;
  const router = useRouter();
  const { operatorSpotApi } = useApiConnector();
  const [selectedSpotId, setSelectedSpotId] = useAtom(selectedSpotIdAtom);
  const isActive = (path: string) =>
    path === routePath ? router.pathname === routePath : router.pathname.startsWith(path);

  const { data: spots } = useQuery({
    queryKey: ["operatorSpotApi.getSpotList", {}],
    queryFn: () => operatorSpotApi.getSpotList({}),
    enabled: !!selectedSpotId,
  });

  const [spotMenuAnchorEl, setSpotMenuAnchorEl] = useState<null | HTMLElement>(null);
  const spotMenuOpen = Boolean(spotMenuAnchorEl);
  const handleSpotMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => setSpotMenuAnchorEl(event.currentTarget);
  const handleSpotMenuClose = () => setSpotMenuAnchorEl(null);
  const handleChangeSpotId = async (spotId: string) => {
    await localforage.setItem(LocalForageKeys.spot.selectedSpotId, spotId);
    setSelectedSpotId(spotId);
    setSpotMenuAnchorEl(null);
  };

  const [settingMenuAnchorEl, setSettingMenuAnchorEl] = useState<null | HTMLElement>(null);
  const settingMenuOpen = Boolean(settingMenuAnchorEl);
  const handleSettingMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) =>
    setSettingMenuAnchorEl(event.currentTarget);
  const handleSettingMenuClose = () => setSettingMenuAnchorEl(null);

  const onClickDashboard = async () => {
    await router.push(routePath);
  };
  const onClickAchievements = async () => {
    await router.push(achievementsPath);
  };
  const onClickEventList = async () => {
    await router.push(eventListPath);
  };
  const onClickPlanList = async () => {
    await router.push(planListPath);
  };
  const onClickReservationList = async () => {
    await router.push(reservationListPath);
  };

  return (
    <Grid css={rootStyled}>
      <Grid css={spotBoxStyled}>
        <Grid css={mainStyled}>
          <Grid css={iconLogoBox}>
            <SvgIconCompany />
          </Grid>
          <Grid css={companySelectorStyled} onClick={handleSpotMenuOpen}>
            <Grid css={spotNameStyled}>{spots?.objects.find((spot) => spot.id === selectedSpotId)?.titleObj.ja}</Grid>
            <SvgIconCaretDown />
          </Grid>
          <Menu anchorEl={spotMenuAnchorEl} open={spotMenuOpen} onClose={handleSpotMenuClose}>
            {spots &&
              spots.objects.map((spot) => (
                <MenuItem key={spot.id} onClick={() => handleChangeSpotId(spot.id)} css={spotMenuStyled}>
                  {spot.titleObj.ja}
                </MenuItem>
              ))}
          </Menu>
        </Grid>
        <Grid css={rightContainerStyled}>
          {APP_ENV === ENV.STAGING && <Grid css={envStyled}>{ENV.STAGING}</Grid>}
          <Grid css={iconBox}>
            <IconButton css={iconSettingstyled} onClick={handleSettingMenuOpen}>
              <SvgIconSetting />
            </IconButton>
          </Grid>
          <SettingMenu anchorEl={settingMenuAnchorEl} open={settingMenuOpen} onClose={handleSettingMenuClose} />
        </Grid>
      </Grid>
      {!props.screenShowNot ? (
        <Grid css={eventBox}>
          <Grid css={eventTicketBox}>
            <Grid css={eventListStyled(isActive(routePath))} onClick={onClickDashboard}>
              {"ダッシュボード"}
            </Grid>
            <Grid css={eventListStyled(isActive(achievementsPath))} onClick={onClickAchievements}>
              {"実績"}
            </Grid>
            <Grid css={eventListStyled(isActive(eventRoot))} onClick={onClickEventList}>
              {"イベント一覧"}
            </Grid>
            <Grid css={eventListStyled(isActive(planRoot))} onClick={onClickPlanList}>
              {"プラン一覧"}
            </Grid>
            <Grid css={eventListStyled(isActive(reservationRoot))} onClick={onClickReservationList}>
              {"予約一覧"}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default Header;

const rootStyled = css`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
`;

const spotBoxStyled = css`
  display: flex;
  width: 100%;
  height: 44px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid ${appColor.Border.grayLightWhite};
  background: ${appColor.Background.bluishGray};
  ${mqMinScreen("lg")} {
    padding: 0px 40px;
  }
`;

const mainStyled = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const iconLogoBox = css`
  display: flex;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: ${appColor.Background.white};
  box-shadow: 0px 2px 5px 0px rgba(65, 69, 82, 0.08);
  cursor: pointer;
`;

const companySelectorStyled = css`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const spotNameStyled = css`
  color: ${appColor.Text.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
`;

const spotMenuStyled = css`
  color: ${appColor.Text.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
`;

const rightContainerStyled = css`
  display: flex;
  flex-direction: row;
  algin-items: center;
  gap: 8px;
`;

const envStyled = css`
  color: ${appColor.Text.gray};
  font-family: ${appTypo.font.kintoSans};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  text-align: center;
  padding: 4px 16px;
  border-radius: 16px;
  border: 2px solid ${appColor.Border.darkGray};
  background-color: ${appColor.Background.white};
`;

const iconSettingstyled = css`
  display: flex;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: ${appColor.Button.icon.color};
  cursor: pointer;
  padding: 0;
`;

const iconBox = css`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const eventBox = css`
  display: flex;
  height: 40px;
  padding: 0px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${appColor.Border.grayUltraLight};
  background: ${appColor.Background.white};
  ${mqMinScreen("lg")} {
    padding: 0px 40px;
  }
`;

const eventListStyled = (isActive: boolean) => css`
  display: flex;
  height: 24px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: ${isActive ? appColor.Background.black : "none"};
  color: ${isActive ? appColor.Text.white : appColor.Text.primary};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 12px */
  cursor: pointer;
`;

// const eventListBox = (isActive) => css`
//   display: flex;
//   height: 24px;
//   padding: 0px 10px;
//   justify-content: center;
//   align-items: center;
//   border-radius: 12px;
//   background: ${appColor.Background.black};
// `;

const ticketListStyled = css`
  color: ${appColor.Background.darkGray};
  text-align: center;
  font-family: ${appTypo.font.kintoSans};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 12px */
  cursor: pointer;
`;

const eventTicketBox = css`
  display: flex;
  align-items: center;
  gap: 8px;
`;
