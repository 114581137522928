import { css } from "@emotion/react";
import Head from "next/head";
import { ReactNode } from "react";

import { Grid } from "@mui/material";

import Header from "./Header/Header";

import { selectedSpotIdAtom } from "@/global-states/common-atoms";
import { useAtomValue } from "jotai";

type Props = {
  pageTitle: string;
  children: ReactNode;
  screenShowNot?: boolean;
};

export const BaseLayout = (props: Props) => {
  const selectedSpotId = useAtomValue(selectedSpotIdAtom);

  return (
    <>
      <Grid css={baseRoot}>
        <Head>
          <title>{`${props.pageTitle} - Go ATAMI`}</title>
        </Head>
        <Header screenShowNot={props.screenShowNot} />
        {selectedSpotId && <Grid css={rootStyled}>{props.children}</Grid>}
      </Grid>
    </>
  );
};

const baseRoot = css`
  height: 100vh;
`;

const rootStyled = css`
  min-height: calc(100vh - 340px);
  height: 100%;
`;
