import { css } from "@emotion/react";

import { DataGrid, DataGridProps } from "@mui/x-data-grid";

import { appColor } from "@/styles/app-colors";
import { appTypo } from "@/styles/app-typography";
import { mqMinScreen } from "@/utils/mediaQuery";

type Props = DataGridProps;

export const BaseDataGrid: React.FC<Props> = (props) => {
  return (
    <DataGrid
      css={eventCheckBox}
      disableColumnMenu
      hideFooterSelectedRowCount
      hideFooter={true}
      columnHeaderHeight={36}
      rowHeight={44}
      autoHeight
      disableRowSelectionOnClick
      {...props}
    />
  );
};

const eventCheckBox = css`
  border: none;
  & .MuiDataGrid-columnHeaders {
    border-bottom: 1px solid ${appColor.Border.grayUltraLight};
  }
  & .MuiDataGrid-columnHeaderTitle {
    color: ${appColor.Text.primary};
    font-family: ${appTypo.font.kintoSans};
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 12px */
  }
  & .MuiDataGrid-withBorderColor {
    border-color: ${appColor.Border.grayUltraLight};
  }
  & .MuiDataGrid-row {
    border-bottom: 1px solid ${appColor.Border.grayUltraLight};
  }
  & .MuiDataGrid-cell {
    color: ${appColor.Text.darkGray};
    font-family: ${appTypo.font.kintoSans};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 14px */
    ${mqMinScreen("lg")} {
      font-size: 14px;
    }
  }
  & .MuiDataGrid-footerContainer {
    border: none;
    margin-top: 24px;
    min-height: 0;
  }
  & .MuiDataGrid-cell,
  & .MuiDataGrid-columnHeader {
    &:focus,
    &:focus-within {
      outline: none;
    }
  }
  & .MuiDataGrid-columnSeparator {
    display: none;
  }
`;
