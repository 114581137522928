import { css } from "@emotion/react";
import React from "react";

import { Menu, MenuItem, MenuProps } from "@mui/material";

import { useLogout } from "@/custom-hooks/auth/useLogout";
import { appColor } from "@/styles/app-colors";

type Props = MenuProps;

const SettingMenu: React.FC<Props> = (props) => {
  const { onSubmitLogout, onSubmitAddressChange, onSubmitPasswordChange } = useLogout();
  return (
    <Menu {...props} css={menuStyled}>
      <MenuItem onClick={() => onSubmitLogout()} css={menuItemStyld}>
        ログアウト
      </MenuItem>
      <MenuItem onClick={() => onSubmitAddressChange()} css={menuItemStyld}>
        メールアドレス変更
      </MenuItem>
      <MenuItem onClick={() => onSubmitPasswordChange()} css={menuItemStyld}>
        パスワード設定
      </MenuItem>
    </Menu>
  );
};

export default React.memo(SettingMenu);

const menuStyled = css`
  & .MuiList-root {
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  & .MuiPaper-root {
    width: 140px;
  }
`;

const menuItemStyld = css`
  display: flex;
  height: 28px;
  align-items: center;
  gap: 4px;
  padding: 0 8px;
  color: ${appColor.Text.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
`;
