import { useRouter } from "next/router";

import { useAuth } from "@/custom-hooks/apis-common/use-auth";

import { authPath, changeMailAddressRoot, changePasswordPath } from "@/constants/app-paths";
import { useSnackbar } from "notistack";

export const useLogout = () => {
  const router = useRouter();
  const { authLogout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmitLogout = async () => {
    try {
      await authLogout();
      enqueueSnackbar("ログアウトしました。", {
        variant: "default",
      });
      await router.push(authPath);
    } catch (err) {
      if (err instanceof Error) {
        enqueueSnackbar("ログアウト処理中に不明なエラーが発生いたしました。時間を空けてからもう一度お試しください。", {
          variant: "error",
        });
      }
    }
  };

  const onSubmitAddressChange = () => {
    router.push(changeMailAddressRoot);
  };

  const onSubmitPasswordChange = () => {
    router.push(changePasswordPath);
  };

  return {
    onSubmitLogout,
    onSubmitAddressChange,
    onSubmitPasswordChange,
  };
};
